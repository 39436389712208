<template>
	<div style="margin-top: 2em">
		<van-sticky>
			<van-nav-bar left-text="返回" left-arrow @click-left="goBack" />
		</van-sticky>
		<van-icon name="checked" size="10em" color="rgb(44 153 69)"></van-icon>
		<div style="font-size: 24px; font-weight: 400">
			<span style="width: 100%; text-align: center">提交成功，等待总部审核！</span>
		</div>
	</div>
</template>

<script>
	export default {
		created() {
			let code = this.getUrlCode('code');
			let entryid = this.getUrlCode('entryid');
			if (code !== undefined && code !== null) {
				//调用后台获取openId
				this.axios({
					method: 'post',
					url: '/v1/web/user-entry/set-open-id',
					data: {
						code: code,
						entryId: entryid,
					},
				});
				this.$router.push('/EntryAdd/Success');
			}
		},
		methods: {
			goBack() {
				this.$router.push('/Login');
			},
			getUrlCode(name) {
				var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
				var r = window.location.search.substring(1).match(reg);
				if (r != null) return decodeURI(r[2]);
				return null;
			},
		},
	};
</script>

<style scope></style>
